.exec-photo {
    width: 100%;
    height: 100%;
}

.exec-list {
    list-style: none;
    margin: 0 auto;
    padding: 0;
    margin-bottom: 20px;
    border-collapse: collapse;
}

.exec-list>li {
    display: inline-block;
    width: 20%;
    height: 33%;
    margin: 15px;
    padding: 10px 0px;
    vertical-align: top;
    min-height: max-content;
    overflow:visible;
    height: max-content;
}

.exec-name {
    font-size: min(2.4vw, 22px);
    font-weight: 600;
    margin-bottom: 5px;
    padding: 10px 15px 0px;
    text-align: left;
}

.exec-title {
    font-size: min(1.7vw, 18px);
    padding: 2px 15px;
    color: rgb(153, 153, 153);
    text-align: left;
    height: max-content;
}

.about-page {
    margin-bottom: 20px;
}