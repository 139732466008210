.centered {

}

.modal {
    width: 800px;
    max-height: 90vh;
    overflow-y: scroll;
    scrollbar-width: none;
    background: white;
    color: black;
    z-index: 10;
    border-radius: 5px;
    padding: 20px 50px;
    text-align: left;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
}

.modal::-webkit-scrollbar {
    display: none;
  }

.modal-head {
    margin: 0;
    padding: 10px;
    font-size: 23px;
    font-weight: 500;
    text-align: center;
    color: black;
}

.modal-button {
    margin-top: 10px;
    cursor: pointer;
    padding: 8px 18px;
    border-radius: 5px;
    font-size: 15px;
    border: none;
    background-color: inherit;
    border: 1px solid rgb(6, 95, 142);
    color: rgb(6, 95, 142);
    display: inline-block;
    margin-left: 10px;
}

.modal-button:hover {
    border-color: rgb(79, 160, 203);
    color: rgb(79, 160, 203);
}

.buttons-container {
    text-align: center;
    margin-top: 10px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.overlay-opacity {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: black;
    opacity: 0.7;
    backdrop-filter: blue(5px);
}

.no-click {
    pointer-events: none;
}

.modal-info {
    color: black;
    font-style: italic;
    margin-top: 5px;
    text-align: center;
}

.delete-modal {
    width: 500px;
    max-height: 90vh;
    background: white;
    color: black;
    z-index: 10;
    border-radius: 5px;
    padding: 20px 50px;
    text-align: left;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
}

.buttons-center {
    text-align: center;
}

.delete-container-button {
    width: 170px;
    font-size: 14px;
    height: 25px;
    background: none;
    border: 1px solid rgb(137, 27, 27);
    color: rgb(137, 27, 27);
    margin-left: 5px;
    border-radius: 7px;
}

.delete-container-button:hover {
    color: white;
    background-color: rgb(137, 27, 27);
    border-color: rgb(137, 27, 27);
}

.edit-container-button {
    width: 170px;
    font-size: 14px;
    height: 25px;
    background: none;
    border: 1px solid rgb(65, 106, 65);
    color:  rgb(65, 106, 65);
    margin-left: 5px;
    border-radius: 7px;
}

.edit-container-button:hover {
    color: white;
    background-color: rgb(65, 106, 65);
    border-color: rgb(65, 106, 65);
}

.delete-event-error {
    color: rgb(137, 27, 27);
    margin-top: 10px;
    margin-bottom: 20px;
}

.centered-spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20%;
}

.date-select {
    width: 100%;
}

.pad-left-10 {
    padding-left: 10px;
}

.pad-top {
    padding-top: 10px;
}