.signup-header {
    margin-bottom: 2.5px;
}

.signup-container {
    text-align: center;
    margin: 0 auto;
    width: 40%;
}

.signup-list {
    list-style: none;
    line-height: 150%;
}

.signup-form {
    text-align: left;
}

.signup-button {
    width: 120px;
    font-size: 14px;
    height: 30px;
    background: none;
    border: 1px solid rgb(62, 62, 62);
    margin-top: 10px;
    border-radius: 7px;
}

.signup-button:hover {
    background-color: rgb(238, 238, 238);
}

.login-info {
    font-size: 25px;
}

.verify-event-success {
    color: rgb(31, 85, 42);
    margin-top: 10px;
    margin-bottom: 20px;
}

.signup-description {
    margin-top: 0px
}

.underline-link:hover {
    cursor: pointer;
}