/**
Sloppy responsive design work has been done to get the announcement for PADDL out quickly
This needs to be redone properly with CSS media queries
 */

.home-page {
    margin-top: -100px;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.header-image {
    height: 35vw;
    width: 100vw;
    background-image: url('../../../assets/homepage/homepage-header-2-titled.png');
    background-size: 100% 100%;
    margin: 0;
}

.home-content{
    margin-top: 0;
}

.about-wecca-home {
    justify-content: center;
    align-items: center;
}

.about-wecca-home > .about-wecca-title {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: 600;
}

.about-wecca-home > .about-wecca-body {
    width: 80%;
    font-size: min(20px, 3vw);
    margin: 20px auto;
    text-align: left;
}

.about-wecca-body > span {
    margin-bottom: 15px;
    display: block;
    line-height: 26px;
}

.image-carousel {
    /* Figure out whether to make this the full width or not */
    margin: 20px auto;
    width: calc(80vw - 1%);
    overflow: hidden;
    height: auto;
    position: relative;
    z-index: -1;
}

.slick-slider {
    line-height: 0;
}

.carousel-image {
    height: max(80vh, 600px);
    width: 80vw;
}

.announcement-container {
    width: 80vw;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    margin: 15px auto;
    height: auto;
    background-color: rgb(243, 240, 246);
    border-radius: 10px;
    padding: 10px;
}

.announcements-section-title {
    font-size: min(34px, 6.5vw);
    font-weight: bold;
}

.announcement-title {
    font-size: min(24px, 4vw);
}

.announcement-text {
    font-size: min(17px, 2.5vw);
    padding: min(10px, 1vw);
}

.announcement-hint-text {
    color: #522584;
    font-size: min(10px, 1.5vw);
}

.announcement-content {
    width: 55%;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.announcement-content-small {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 15px auto;
}

.announcement-image-container {
    width: 40%;
    height: auto;
    display: inline-block;
}

.announcement-image-container-small {
    width: 95%;
    height: auto;
    display: inline-block;
    border-radius: 2px;
}

.announcement-image {
    height: 100%;
    width: 100%;
}

.announcement-download-links {
    display: flex;
    justify-content: space-evenly;
    font-size: min(18px, 2.5vw);
    padding: min(10px, 1vw);
}

.announcement-download-links a {
    text-decoration: none;
}

.announcement-download-links a:hover {
    text-decoration: underline;
}


.social-icon {
    color: #522584;
    fill: #522584;
    font-size: 60px;
    margin: 0 3vw 0;
}

.social-icon:hover {
    color: #9065c2;
    fill: #9065c2;
    cursor: pointer;
}

.social-icon-container {
    display: flex;
    justify-content: space-around;
    margin: 0 max(20px,20%) 0;
    align-items: center;
}

.social-icon-container-mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 10px;
    margin: 0 max(20px,20%) 0;
}
